import React from 'react';
import { Container, Center, Space } from '@mantine/core';
import Header from './Header'
import Footer from './Footer'

function Impressum() {
    return (
        <Container>
            <Header></Header>
            <Space h="xl"></Space>
            <Space h="xl"></Space>
            <Center>

                <div className='div_text'>
                    <strong>Angaben gemäß § 5 TMG</strong>
                    <p>
                        Bithand GmbH<br></br>
                        Robert-Bunsen-Straße 31<br></br>
                        28357 Bremen<br></br>
                    </p>

                    <p>
                        Handelsregister: HRB 28817 HB<br></br>
                        Registergericht: Bremen<br></br>
                    </p>

                    <p>
                        Vertreten durch:<br></br>
                        Geschäftsführer : Dipl. Inf. Thomas Arndt<br></br>
                        Kontakt<br></br>
                    </p>

                    <p>
                        Telefon: +49 (0) 421 514 262 53<br></br>
                        E-Mail: info@bithand.de<br></br>
                        Umsatzsteuer-ID<br></br>
                    </p>

                    <p>
                        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE290958736<br></br>
                    </p>
                </div>
            </Center>
            <Footer></Footer>
        </Container>
    )
}

export default Impressum