import React from 'react';
import { Center, Space, Grid, Image, Group } from '@mantine/core';
import logo_objektschutz from "../images/objektschutz.png"
import logo_videoueberwachung from "../images/videoüberwachung.png"
import logo_warensicherung from "../images/warensicherung.png"
import logo_einbruchschutz from "../images/einbruchschutz.png"
import logo_sicherheit_ki from "../images/sicherheit mit ki.png"
import logo_abwehr_spionage from "../images/abwehr spionage.png"


function Anwendungen() {
    return (
        <>
            <Center>
                <h2>Anwendungsbereiche</h2>
            </Center>

            <Grid>
                <Grid.Col span={1}></Grid.Col>
                <Grid.Col span={10}>

                    <Grid>
                        <Grid.Col span={{ xl: 4, lg: 4, md: 4, sm: 12, base: 12 }} >
                            <div class="img-hover-zoom img-hover-zoom--colorize" >
                                <Image
                                    radius="xl"
                                    h={100}
                                    w="auto"
                                    fit="contain"
                                    src={logo_objektschutz}
                                    style={{ margin: "auto" }}
                                    className='responsive-image'
                                />
                            </div>

                        </Grid.Col>
                        <Grid.Col span={{ xl: 8, lg: 8, md: 8, sm: 12, base: 12 }} >
                            <Group h={100}>
                                <div style={{ textAlign: "center" }} className='fontsize_3'>
                                    Videoüberwachung und Videoabsicherung
                                </div>

                            </Group>

                        </Grid.Col>

                    </Grid>
                    <Space h="lg"></Space>
                    <Grid>
                        <Grid.Col span={{ xl: 4, lg: 4, md: 4, sm: 12, base: 12 }} >
                            <div class="img-hover-zoom img-hover-zoom--colorize ">
                                <Image
                                    radius="xl"
                                    h={100}
                                    w="auto"
                                    fit="contain"
                                    src={logo_videoueberwachung}
                                    style={{ margin: "auto" }}
                                />
                            </div>
                        </Grid.Col>
                        <Grid.Col span={{ xl: 8, lg: 8, md: 8, sm: 12, base: 12 }} >
                            <Group h={100}>
                                <div style={{ textAlign: "center" }} className='fontsize_3'>
                                    Objektschutz und Perimeterabsicherung
                                </div>
                            </Group>

                        </Grid.Col>

                    </Grid>
                    <Space h="lg"></Space>
                    <Grid>
                        <Grid.Col span={{ xl: 4, lg: 4, md: 4, sm: 12, base: 12 }} >
                            <div class="img-hover-zoom img-hover-zoom--colorize ">
                                <Image
                                    radius="xl"
                                    h={100}
                                    w="auto"
                                    fit="contain"
                                    src={logo_warensicherung}
                                    style={{ margin: "auto" }}
                                />
                            </div>

                        </Grid.Col>
                        <Grid.Col span={{ xl: 8, lg: 8, md: 8, sm: 12, base: 12 }} >
                            <Group h={100}>
                                <div style={{ textAlign: "center" }} className='fontsize_3'>
                                    Absicherung von Waren im Einzel- und Großhandel
                                </div>

                            </Group>

                        </Grid.Col>

                    </Grid>
                    <Space h="lg"></Space>
                    <Grid>
                        <Grid.Col span={{ xl: 4, lg: 4, md: 4, sm: 12, base: 12 }} >
                            <div class="img-hover-zoom img-hover-zoom--colorize ">
                                <Image
                                    radius="xl"
                                    h={100}
                                    w="auto"
                                    fit="contain"
                                    src={logo_einbruchschutz}
                                    style={{ margin: "auto" }}
                                />
                            </div>


                        </Grid.Col>
                        <Grid.Col span={{ xl: 8, lg: 8, md: 8, sm: 12, base: 12 }} >
                            <Group h={100}>
                                <div style={{ textAlign: "center" }} className='fontsize_3'>
                                    Einbruchschutz und Einbruchsprävention
                                </div>

                            </Group>

                        </Grid.Col>

                    </Grid>
                    <Space h="lg"></Space>
                    <Grid>
                        <Grid.Col span={{ xl: 4, lg: 4, md: 4, sm: 12, base: 12 }} >
                            <div class="img-hover-zoom img-hover-zoom--colorize" >
                                <Image
                                    radius="xl"
                                    h={100}
                                    w="auto"
                                    fit="contain"
                                    src={logo_sicherheit_ki}
                                    style={{ margin: "auto" }}
                                />
                            </div>

                        </Grid.Col>
                        <Grid.Col span={{ xl: 8, lg: 8, md: 8, sm: 12, base: 12 }} >
                            <Group h={100}>
                                <div style={{ textAlign: "center" }} className='fontsize_3'>
                                    Sicherheit mit KI für Prävention und Auswertung
                                </div>

                            </Group>

                        </Grid.Col>


                    </Grid>
                    <Space h="lg"></Space>
                    <Grid>
                        <Grid.Col span={{ xl: 4, lg: 4, md: 4, sm: 12, base: 12 }} >
                            <div class="img-hover-zoom img-hover-zoom--colorize ">
                                <Image
                                    className='img-hover-zoom img-hover-zoom--colorize'
                                    radius="xl"
                                    h={100}
                                    w="auto"
                                    fit="contain"
                                    src={logo_abwehr_spionage}
                                    style={{ margin: "auto" }}
                                />
                            </div>

                        </Grid.Col>
                        <Grid.Col span={{ xl: 8, lg: 8, md: 8, sm: 12, base: 12 }} >
                            <Group h={100}>
                                <div style={{ textAlign: "center" }} className='fontsize_3'>
                                    Abwehr von Spionage mit optoelektronischen Maßnahmen
                                </div>

                            </Group>

                        </Grid.Col>

                    </Grid>




                </Grid.Col >
                <Grid.Col span={1}></Grid.Col>
            </Grid >

        </>
    )
}

export default Anwendungen