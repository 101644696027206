import React from 'react';
import { Container, Center, Space } from '@mantine/core';
import Header from './Header'
import Footer from './Footer'

function About() {
    return (
        <Container>
            <Header></Header>
            <Space h="xl"></Space>
            <Space h="xl"></Space>
            <Center>
                <div className='div_text'>
                    <h2 style={{ textAlign: "left" }}>
                        Entdecken Sie das Team hinter P.R.O.T.E.C.T
                    </h2>
                    Bei P.R.O.T.E.C.T (Professionelle Risikoabwehr und Optronische Technologische Einsatzlösungen) arbeitet ein Team von enthusiastischen Ingenieuren und Entwicklern, die sich der Schaffung zukunftsweisender Sicherheitstechnologien verschrieben haben. Ihre Arbeit ist von Leidenschaft und Hingabe geprägt, und sie streben danach, die Grenzen des Möglichen zu erweitern.
                    <br></br><br></br>
                    Das Team besteht aus Experten in verschiedenen Bereichen – von Softwareentwicklern, die intuitive und leistungsfähige Anwendungen kreieren, bis hin zu Hardware-Ingenieuren, die maßgeschneiderte und hochspezialisierte Sicherheitskomponenten entwickeln. Sie arbeiten eng zusammen, um nahtlose Integrationen von Hardware und Software zu ermöglichen, und bieten maßgeschneiderte Sicherheitslösungen, die speziell auf die Bedürfnisse ihrer Kunden zugeschnitten sind.
                    <br></br><br></br>
                    Die Ingenieure bei P.R.O.T.E.C.T sind nicht nur technische Spezialisten, sondern auch kreative Denker. Sie analysieren die spezifischen Anforderungen und Rahmenbedingungen jedes Projekts und überführen diese in umfassende Sicherheitskonzepte. Ihre Arbeit reicht von der Konzept- und Planungsphase bis hin zur Entwicklung von Software und Hardware, wobei sie stets darauf achten, dass alle Komponenten perfekt aufeinander abgestimmt sind.
                    <br></br><br></br>
                    Der Ansatz von P.R.O.T.E.C.T basiert auf einer holistischen Herangehensweise. Sie verstehen Sicherheit nicht nur als technische Herausforderung, sondern als integriertes System, das maximale Effektivität und Zuverlässigkeit gewährleistet. Dabei setzen sie auf die interdisziplinäre Verschaltung verschiedener Bereiche und erzielen so höchste Wirksamkeit.
                    <br></br><br></br>
                    Die Philosophie des Teams ist es, stets über den Tellerrand hinauszuschauen und innovative Lösungen zu entwickeln, die die Sicherheitsanforderungen ihrer Kunden nicht nur erfüllen, sondern übertreffen. Ihre Arbeit ist ein Beweis dafür, dass technische Exzellenz und kreatives Denken Hand in Hand gehen können, um außergewöhnliche Ergebnisse zu erzielen.
                    <br></br><br></br>
                </div>
            </Center>
            <Footer></Footer>
        </Container>
    )
}

export default About