// In Navbar.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Space, NavLink, Group, Stack, Avatar } from '@mantine/core';
import logo_protect from "../images/logo_alpha_small.png"

function Footer() {
    const navigate = useNavigate()
    return (
        <>
            <Space h="xl" />
            <Space h="xl" />

            <Group justify="center" gap="sm" grow>


                <div>
                    <Stack>
                        <Avatar src={logo_protect} alt="protect" />
                        <div className='fontsize'>
                            P.R.O.T.E.C.T ist ein Service der Bithand GmbH
                        </div>
                    </Stack>
                </div>
                <div><Space></Space></div>
                <div>
                    <Box gap="xs" spacing="xs" >
                        <NavLink
                            className='custom-navlink'
                            color="cyan"
                            label="P.R.O.T.E.C.T"
                            variant="filled"

                            onClick={(event) => {
                                navigate("/")
                            }}
                        ></NavLink>
                        <NavLink
                            className='custom-navlink'
                            color="cyan"
                            label="Über uns"
                            variant="filled"
                            onClick={(event) => {
                                navigate("/about")
                            }}
                        ></NavLink>
                        <NavLink
                            className='custom-navlink'
                            color="cyan"
                            label="Datenschutz"
                            variant="filled"
                            onClick={(event) => {
                                navigate("/datenschutz")
                            }}
                        >
                        </NavLink>
                        <NavLink
                            className='custom-navlink'
                            color="cyan"
                            label="Impressum"
                            variant="filled"
                            onClick={(event) => {
                                navigate("/impressum")
                            }}
                        >
                        </NavLink>
                    </Box>
                </div>

            </Group>

            <Space h="xl"></Space>
            <Group justify="center" gap="sm" grow>
                <div className='fontsize'>
                    © Bithand 2023
                </div>
            </Group>


        </>
    );

}

export default Footer;