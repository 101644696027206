// In Navbar.js
import React from 'react';
import { Image, Space, Center, Group, Button, Dialog, Text, TextInput, PasswordInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import logo from "../images/image.png"
import logo_protect from "../images/logo_alpha_small.png"

function Header() {
    const [opened, { toggle, close }] = useDisclosure(false);
    return (

        <div>
            <Space h="xl" />
            <Group justify="space-between" gap="sm">
                <div>
                    <Image
                        radius="xs"
                        h={100}
                        w="auto"
                        fit="contain"
                        src={logo_protect}
                    />
                </div>
                <Group>
                    <Button variant="outline" color="cyan" onClick={toggle}>Kundenlogin</Button>
                </Group>
            </Group>
            <Space h="xl" />
            <Space h="xl" />
            <Center style={{ marginRight: "30px", marginLeft: "30px" }}>
                <h1>P.R.O.T.E.C.T</h1>
            </Center>

            <h2 style={{ marginRight: "30px", marginLeft: "30px", textAlign: "center" }}>Spezialisiert auf Hochsicherheitslösungen <br></br>und innovative Sicherheitssoftware </h2>

            <Space h="xl" />
            <Space h="xl" />

            <Group justify="space-between">
                <Space></Space>
                <div className='image-container'>
                    <Image
                        radius="xs"

                        src={logo}
                        style={{ filter: "grayscale(100%)" }}
                        className='responsive-image'
                    />
                </div>
                <Space></Space>
            </Group>
            <Dialog opened={opened} withCloseButton onClose={close} size="lg" radius="md" position={{ top: 20, left: 20 }}

                style={{ backgroundColor: "#333" }}
            >
                <Text size="sm" mb="xs" fw={500}>
                    Benutzeranmeldung
                </Text>

                <Group align="flex-end">
                    <TextInput placeholder="Nutzername" style={{ flex: 1 }} />
                    <PasswordInput placeholder="Passwort" style={{ flex: 1 }} />
                    <Button onClick={close} color='#15aabf' variant='outline'>Login</Button>
                </Group>
            </Dialog>

        </div>
    );

}

export default Header;