//import logo from './logo.svg';
import './App.css';
import Datenschutz from './components/Datenschutz';
import Impressum from './components/Impressum';
import MainPage from './components/MainPage';
import About from './components/About';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MantineProvider, Container } from '@mantine/core';
import cx from 'clsx';
import classes from './index.css';
import { createTheme } from '@mantine/core';



function App() {

  const theme = createTheme({
    components: {
      Container: Container.extend({
        classNames: (_, { size }) => ({
          root: cx({ [classes.responsiveContainer]: size === 'responsive' }),
        }),
      }),
    },
  })

  return (
    <MantineProvider theme={theme}>
      <div className='header_color'>
        <Router>
          <Routes>
            {/*  <Route path="/" element={<Login></Login>}></Route> */}
            <Route path="/impressum" element={<Impressum />}></Route>
            <Route path="/" element={<MainPage />}></Route>
            <Route path="/datenschutz" element={<Datenschutz />}></Route>
            <Route path="/about" element={<About />}></Route>
          </Routes>
        </Router >
      </div>
    </MantineProvider>
  );
}

export default App;


