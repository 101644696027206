import React from 'react'
import logo_protect from "../images/logo_alpha_small.png"
import { Space, Center, Grid, Avatar, Divider, Stack, Alert, Button, Accordion } from '@mantine/core'
import Header from './Header'
import Footer from './Footer'
import Angebote from './Angebote'
import Anwendungen from './Anwendungen'
import Technologien from './Technologien'


function MainPage() {

    return (
        <Grid
            style={{
                marginLeft: "20px",
                marginRight: "20px"
            }}
            justify="center"
            gutter={5}
        >
            <Grid.Col span={{ base: 1, xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}></Grid.Col>
            <Grid.Col span={{ xxs: 10, xs: 10, sm: 10, md: 8, lg: 8, xl: 8 }}>
                <Header></Header>

                <Space h="xl" />
                <Space h="xl" />
                <Space h="xl" />

                <Center>
                    <h2>Entdecken Sie P.R.O.T.E.C.T </h2>
                </Center>

                <Center>
                    <div className='div_text'>
                        P.R.O.T.E.C.T (Professionelle Risikoabwehr und Optronische Technologische Einsatzlösungen) ist die Zukunft der Sicherheitstechnologie.
                        Wir bieten maßgeschneiderte Sicherheitslösungen, die auf jahrelanger Erfahrung und den aktuellsten Technologien basieren.
                        Unsere Expertise umfasst ein breites Spektrum an Produkten und Dienstleistungen – von hochentwickelter Sicherheitssoftware bis hin zu intelligenten Schutzsystemen,
                        die speziell für anspruchsvolle Umgebungen entwickelt wurden. Ob für Regierungsbehörden, Forschungseinrichtungen oder private Unternehmen, P.R.O.T.E.C.T steht für Verlässlichkeit,
                        Innovation und höchste Sicherheitsstandards.
                    </div>
                </Center>
                <Space h="xl" />
                <Space h="xl" />

                <Angebote></Angebote>

                <Anwendungen></Anwendungen>

                <Space h="lg"></Space>
                <Divider my="xl" size="xs" />

                <Technologien></Technologien>


                <Space h="xl" />
                <Space h="xl" />
                <Center>
                    <div className='div_text'>
                        Die Integration von Konzept & Planung, Hardware- und Softwareentwicklung ist entscheidend, um eine umfassende und nahtlos funktionierende Sicherheitslösung zu bieten.
                        Diese holistische Herangehensweise stellt sicher, dass alle Komponenten perfekt aufeinander abgestimmt sind und somit
                        die Effektivität und Zuverlässigkeit des Gesamtsystems maximiert wird.
                    </div>
                </Center>
                <Space h="xl" />
                <Space h="xl" />



                <Alert variant="blue" color="#d9e3ea"  >
                    <div style={{ color: "#d9e3ea", textAlign: "center" }} className='div_text'>
                        Erfahren Sie mehr über unsere innovativen Lösungen <br></br>und wie wir Ihre Sicherheitsanforderungen erfüllen können.
                        <br></br><br></br>
                        Kontaktieren Sie uns jetzt für eine persönliche Beratung und schützen Sie das, was Ihnen wichtig ist.
                    </div>
                    <Space h="xl"></Space>

                    <Accordion variant="filled" chevron={false} className='accordion-item'>
                        <Accordion.Item value="photos" className='accordion-item_2'>
                            <Accordion.Control
                                className='accordion-item_2'
                            >
                                <Button size="md" variant="outline" color='cyan' fullWidth

                                >Kontakt aufnehmen</Button>
                            </Accordion.Control>
                            <Accordion.Panel className='accordion-item_2'>

                                <Center>
                                    <Stack align="center">

                                        <Space h="xl"></Space>
                                        <div className='div_text'>
                                            Mail : kontakt@protect-hes.de
                                        </div>

                                        <div className='div_text'>
                                            Telefon : +49 (0) 421 514 262 53
                                        </div>

                                        <Space h="md"></Space>

                                        <div style={{ textAlign: "center" }} className='div_text'>
                                            Sie möchten von uns kontaktiert werden?<br></br>
                                            <br></br>
                                            Schreiben sie direkt im Chat an oder hinterlassen uns eine Nachricht. Wir melden uns schnellstmöglich zurück.

                                        </div>

                                        <Space h="sm"></Space>
                                        <Center>
                                            <Avatar src={logo_protect} alt="protect" />
                                        </Center>
                                        <Space h="xl"></Space>
                                    </Stack>
                                </Center>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>

                </Alert>


                <Footer></Footer>

            </Grid.Col >

            <Grid.Col span={{ base: 1, xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}></Grid.Col>

        </Grid >

    );
}

export default MainPage;