import React from 'react';
import { Center, Space, Stack, SimpleGrid, Image } from '@mantine/core';
import logo_planung from "../images/planung.png"
import logo_programming from "../images/programmieren.png"
import logo_hardware from "../images/hardware.png"

function Technologien() {
    return (
        <>
            <Center>
                <Stack>
                    <h2 style={{ textAlign: "center" }}>Die Technologien</h2>
                    <div className='div_text'>
                        Höchste Wirksamkeit wird durch die interdisziplinäre Verschaltung verschiedener Bereiche erzielt
                    </div>
                </Stack>
            </Center>
            <Space h="xl" />
            <Space h="x1"></Space>
            <SimpleGrid
                cols={{ base: 1, sm: 1, lg: 2 }}
                spacing={{ base: 10, sm: 'md', lg: 'xl' }}
                verticalSpacing={{ base: 'md', sm: 'md', lg: 'xl' }}>
                <div>
                    <Stack>
                        <h3>Konzept & Planung</h3>
                        <div className='div_text'>
                            In der Phase der Konzept- und Planung wird der Grundstein für erfolgreiche Sicherheitslösungen gelegt.
                            Hier werden die spezifischen Anforderungen und Rahmenbedingungen Ihres Anliegens analysiert und in ein
                            umfassendes Sicherheitskonzept überführt.
                        </div>
                    </Stack>
                </div>
                <div>
                    <Center>
                        <div class="img-hover-zoom img-hover-zoom--colorize ">
                            <Image
                                radius="xs"
                                h={300}
                                w="auto"
                                fit="contain"
                                src={logo_planung}
                            />
                        </div>
                    </Center>
                </div>
                <div>
                    <Center>
                        <div class="img-hover-zoom img-hover-zoom--colorize ">
                            <Image
                                radius="xs"
                                h={300}
                                w="auto"
                                fit="contain"
                                src={logo_programming}
                            />
                        </div>
                    </Center>
                </div>
                <div>
                    <Stack>
                        <h3>Softwarekonzept</h3>
                        <div className='div_text'>
                            In der Softwareentwicklung legen wir großen Wert darauf, intuitive und leistungsfähige Anwendungen zu kreieren,
                            die unsere Hardwarekomponenten ergänzen und optimieren. Unsere Softwarelösungen bieten fortschrittliche Funktionen
                            und Benutzerfreundlichkeit, um maximale Sicherheit und Effizienz zu gewährleisten.
                        </div>
                    </Stack>
                </div>
                <div>
                    <Stack>
                        <h3>Hardwaredesign</h3>
                        <div className='div_text'>
                            Die Entwicklung eigener Hardware ermöglicht es uns, maßgeschneiderte und hochspezialisierte Sicherheitskomponenten zu konstruieren,
                            die genau auf die Bedürfnisse und Anforderungen unserer Kunden zugeschnitten sind.
                            Durch die Kontrolle über den gesamten Herstellungsprozess können wir höchste Qualität
                            und Funktionalität unserer Sicherheitsprodukte garantieren.
                        </div>
                    </Stack>
                </div>
                <div>
                    <Center>
                        <div class="img-hover-zoom img-hover-zoom--colorize ">
                            <Image
                                radius="xs"
                                h={300}
                                w="auto"
                                fit="contain"
                                src={logo_hardware}
                            />
                        </div>
                    </Center>
                </div>
            </SimpleGrid>
        </>
    )
}

export default Technologien