import React from 'react';
import { Center, Space, Grid, Divider, Avatar } from '@mantine/core';
import { BsFillDiagram3Fill, BsLayerForward, BsOpticalAudio, BsPcHorizontal, BsUpcScan, BsPersonVcard } from "react-icons/bs"

function Angebote() {
    return (
        <>
            <Center>
                <h2>Was bieten wir an</h2>
            </Center>

            <Space h="xl" />
            <Space h="xl" />

            <Grid gutter="xl" justify="center">

                <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                    <Center>
                        <Avatar color="#4b95af" radius="xl" size="xl" className='custom_background'>
                            <BsLayerForward size="2.0rem" />
                        </Avatar>
                    </Center>
                    <Center>
                        <h3>Integrierte Sicherheitssysteme</h3>
                    </Center>
                    <Center>
                        <div className='div_text'>
                            Nahtlose Integration von Hardware und Software für ein umfassendes
                            Sicherheitserlebnis.
                        </div>
                    </Center>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                    <Center>
                        <Avatar color="#4b95af" radius="xl" size="xl" className='custom_background'>
                            <BsPcHorizontal size="2.0rem" />
                        </Avatar>
                    </Center>

                    <h3>Maßgeschneiderte Sicherheitslösungen</h3>

                    <Center>
                        <div className='div_text'>
                            Individuell konzipierte Sicherheitskonzepte, die auf spezifische Kundenanforderungen
                            zugeschnitten sind, einschließlich Hardware und Software.
                        </div>
                    </Center>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                    <Center>
                        <Avatar color="#4b95af" radius="xl" size="xl" className='custom_background'>
                            <BsOpticalAudio size="2.0rem" />
                        </Avatar>
                    </Center>

                    <h3>HighEnd Überwachungstechnik</h3>

                    <Center>
                        <div className='div_text'>
                            Entwicklung und Bereitstellung von modernsten Überwachungssystemen und Sensortechnologien.
                        </div>
                    </Center>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                    <Center>
                        <Avatar color="#4b95af" radius="xl" size="xl" className='custom_background'>
                            <BsFillDiagram3Fill size="2.0rem" />
                        </Avatar>
                    </Center>

                    <h3>Erfahrung und technologische Expertise</h3>

                    <Center>
                        <div className='div_text'>
                            Umfassendes Fachwissen in der Entwicklung von Sicherheitshardware und -software,
                            gestützt durch jahrelange Erfahrung.
                        </div>
                    </Center>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                    <Center>
                        <Avatar color="#4b95af" radius="xl" size="xl" className='custom_background'>
                            <BsUpcScan size="2.0rem" />
                        </Avatar>
                    </Center>

                    <h3>Lösungen für verschiedene Branchen</h3>

                    <Center>
                        <div className='div_text'>
                            Maßgeschneiderte Hardware- und Softwarelösungen für Regierungsbehörden,
                            Forschungseinrichtungen und private Unternehmen.
                        </div>
                    </Center>
                </Grid.Col>

                <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
                    <Center>
                        <Avatar color="#4b95af" radius="xl" size="xl" className='custom_background'>
                            <BsPersonVcard size="2.0rem" />
                        </Avatar>
                    </Center>

                    <h3>Persönliche Beratung vor Ort</h3>

                    <Center>
                        <div className='div_text'>
                            Wir arbeiten zuverlässig und diskret, um optimale und individuell zugeschnittene
                            Sicherheitslösungen zu liefern.
                        </div>
                    </Center>
                </Grid.Col>

            </Grid>
            <Space h="xl" />
            <Divider my="xl" size="xs"></Divider>
        </>
    )
}

export default Angebote